body, html {
    padding: 0;
    margin: 0;
    background-color: black;
}
.article.container {
    background-color: black;
    padding-top: 40px;
}
.article .img_container {
    position: relative;
}
.article .img {
    width: 100%;
    image-resolution: from-image;
    resize: both;
}
.article .img_gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0,0,0,0) 55%, rgba(0,0,0,0.2) 65%, rgba(0,0,0,0.25) 80%, rgba(0,0,0,0.3) 90%, rgba(0,0,0,0.45) 100%);
}
.description_area {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    padding: 0px 5%;
}
.title {
    color: white;
    font-size: 5em;
    font-weight: 200;
    margin-bottom: 7%;
    font-family: 'Noto Sans', sans-serif;
}
.subtitle {
    color: white;
    font-size: 1.8em;
    font-weight: 200;
    margin-bottom: 7%;
    font-family: 'Noto Sans', sans-serif;
}
.description {
    color: white;
    font-size: 1em;
    font-weight: 100;
    margin-bottom: 90px;
    font-family: 'Noto Sans', sans-serif;
    line-height: 150%;
}

.horizontal_line {
    color: rgba(255, 255, 255, 0.2);
    height: 0.5px;
    width: 100%;
    margin-bottom: 50px;
}

.footer {
    background-color: white;
    display: flex;
    flex-direction: row;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.bookmarked_area {
    margin-top: 20px;
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
}
.bookmark_bottom_icon {
    margin-right: 30px;
}
.bookmark_bottom_icon_img {
    width: 50px;
}

.bookmark_bottom_text {
    font-size: 32px;
    font-weight: 600;
    font-family: 'Noto Sans', sans-serif;
}
