body, html {
    padding: 0;
    margin: 0;
    background-color: black;
}
.container_area {
    border-width: 0;
}
.my_bookmarks.container {
    background-color: black;
    padding: 40px;
}
.body_container {
    flex-direction: column;
}
.add_bookmark_icon_text {
    margin-left: 20px;
    margin-right: 20px;
}
.add_bookmark_icon_text img {
    width: 50px;
}
.title {
    color: white;
    font-size: 50px;
    font-weight: 200;
    margin-top: 0;
    font-family: 'Noto Sans', sans-serif;
    padding-top: 7px;
}
.carousel_container {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-right: 20px;
}
.carousel_item {
    margin-top: 10px;
    position: relative;
    overflow: hidden;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    margin-bottom: 40px;
}
.text_container {
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100% - 40px);
    width: calc(100% - 30px);
    padding: 15px;
}
.close_text_container {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 5px;
    margin-right: 10px;
    position: absolute;
    top: 0;
    right: 12px;
}
.close_text_container a{
    border-bottom: 0px;
    text-decoration: none;
    font-weight: bold;
}
.close_text {
    border-bottom: none;
    align-items: flex-end;
    color: white;
    font-size: 50px;
    font-family: 'Noto Sans', sans-serif;
}
.carousel_img_container {
    overflow: hidden;
}
.carousel_img {
    border-radius: 7px;
    height: 20vh;
    border: 1px solid white;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
}
.carousel_img_gradient {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0,0,0,0) 55%, rgba(0,0,0,0.2) 65%, rgba(0,0,0,0.25) 80%, rgba(0,0,0,0.3) 90%, rgba(0,0,0,0.45) 100%);
}
.carousel_title_container {
    display: flex;
    flex-direction: column-reverse;
    margin-left: 15px;
    width: 85%;
    position: absolute;
    bottom: 15px;
    left: 15px;
}
.carousel_title {
    color: white;
    align-items: flex-start;
    font-family: 'Noto Sans', sans-serif;
    margin-bottom: 5px;
    font-size: 32px;
}
.close_text_container a{

}
