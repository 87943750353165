.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 35px;
    top: 50px;
}
.menu_icon img{
    width: 50px;
}
.property_icon img {
    width: 300px;
}
.bookmark_icon img{
    width: 50px;
}
